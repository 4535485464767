<template>
    <div v-if="editable || textLeftValue && textRightValue" class="slider">
        <div class="d-flex justify-content-between">
            <!-- Input left -->
            <input v-if="editable" class="slider__input slider__input--left form-control" type="text" placeholder="Type text..." aria-label="Personality trait" :value="textLeftValue" @input="$emit('update:textLeftValue', $event.target.value);">
            <span v-else :class="`slider__input ${[ rangeValue <= 50 ? 'switch' : '' ]}`">{{ textLeftValue }}</span>
            <!-- Input right -->
            <input v-if="editable" class="slider__input slider__input--right form-control" type="text" placeholder="Type text..." aria-label="Personality trait" :value="textRightValue" @input="$emit('update:textRightValue', $event.target.value);">
            <span v-else :class="`slider__input ${[ rangeValue > 50 ? 'switch' : '' ]}`">{{ textRightValue }}</span>
        </div>
         <!-- Slider -->
        <input type="range" min="1" max="99" :class="`slider__range ${[ rangeValue > 50 ? 'switch' : '' ]}`" :value="rangeValue" @input="$emit('update:rangeValue', $event.target.value);" @click="test" :disabled="!editable">
        <button v-if="editable" @click="hideSlider($event); removeData(index)" type="button" class="slider__delete-btn"><inline-svg :src="require(`../assets/svg/icon-trash.svg`)" /></button>
    </div>
</template>

<script>
    import InlineSvg from 'vue-inline-svg';

    export default {
        name: 'Slider',

        components: {
            InlineSvg
        },

        props: {
            editable: {
                type: Boolean,
                required: false,
                default: false,
            },
            index: {
                type: Number,
                required: false,
            },
            rangeValue: {
                type: String,
                default: '50',
                required: true
            },
            textLeftValue: {
                type: String,
                default: '',
                required: false,
            },
            textRightValue: {
                type: String,
                default: '',
                required: false,
            },
        },

        methods: {
            hideSlider: function(e) {
                e.target.parentElement.parentElement.classList.add('hide');
            },
            removeData: function(index) {
                this.$parent.removeElement(index, 'slider');
            },
        },
    }
</script>
